import HoverBorder from './wrappers/HoverBorder'

function FindMe({hide = []}) {

    return (
        <div>
            <div className="inline-flex gap-3 items-center">

                { !hide.includes(0) &&  <HoverBorder link="https://www.linkedin.com/in/bmamatahir/">
                    <svg fill="currentColor" baseProfile="tiny" height="30px" viewBox="0 0 24 24" width="30px"
                         xmlns="https://www.w3.org/2000/svg">
                        <path
                            d="M8,19H5V9h3V19z M19,19h-3v-5.342c0-1.392-0.496-2.085-1.479-2.085c-0.779,0-1.273,0.388-1.521,1.165C13,14,13,19,13,19h-3   c0,0,0.04-9,0-10h2.368l0.183,2h0.062c0.615-1,1.598-1.678,2.946-1.678c1.025,0,1.854,0.285,2.487,1.001   C18.683,11.04,19,12.002,19,13.353V19z"/>
                        <ellipse cx="6.5" cy="6.5" rx="1.55" ry="1.5"/>
                    </svg>
                </HoverBorder>}


                { !hide.includes(1) &&  <HoverBorder link="https://github.com/bmamatahir">

                    <svg fill="currentColor" height="24" viewBox="0 0 512 512" width="24"
                         xmlns="https://www.w3.org/2000/svg">
                        <path
                            d="M169.175,272.861c-24.725,0-35.169,32.773-35.169,52.803c0,20.031,10.444,52.807,35.169,52.807    c24.727,0,35.171-32.775,35.171-52.807C204.346,305.635,193.901,272.861,169.175,272.861z"/>
                        <path
                            d="M345.221,272.861c-24.726,0-35.17,32.773-35.17,52.803c0,20.031,10.444,52.807,35.17,52.807s35.171-32.775,35.171-52.807    C380.392,305.635,369.946,272.861,345.221,272.861z"/>
                        <path
                            d="M448.624,167.828c4.888-14.853,7.379-30.475,7.379-46.191c0-20.604-4.599-30.954-13.992-49.641    c-42.933,0-70.724,8.145-103.306,33.733c-25.109-6.039-51.176-8.818-77.052-8.818c-28.652,0-57.211,2.971-85.003,9.584    c-33.062-25.876-60.854-34.5-104.267-34.5c-9.295,18.687-13.991,29.037-13.991,49.641c0,15.716,2.396,31.624,7.379,46.767    c-26.45,30.762-39.771,68.712-39.771,108.867c0,30.571,5.366,63.248,19.358,91.042c37.758,74.27,143.558,71.681,216.198,71.681    c71.492,0,171.351,1.725,207.672-71.681c13.702-28.08,16.77-60.471,16.77-91.042C485.999,237.116,474.979,198.878,448.624,167.828    z M280.054,422.745h-46.191c-67.083,0-144.133-12.938-144.133-97.081c0-42.069,25.589-79.157,70.438-79.157    c18.209,0,35.746,3.258,53.667,5.75c14.376,2.204,28.652,3.066,43.22,3.066c14.661,0,28.942-0.862,43.223-3.066    c18.207-2.492,35.553-5.75,53.665-5.75c44.851,0,70.437,37.087,70.437,79.157C424.378,409.902,347.425,422.745,280.054,422.745z"/>
                    </svg>

                </HoverBorder>}


                { !hide.includes(2) &&  <a className="hover:underline hover:text-yellow-300 font-medium whitespace-nowrap" href="mailto:b.mamatahir@gmail.com">Hire me</a>}


                { !hide.includes(3) &&  <a href={"/software-engineer.pdf"} className={"whitespace-nowrap animated-border text-yellow-300 font-medium border-b-2 border-yellow-300"}>Download CV</a>}
            </div>
        </div>
    );
}

export default FindMe;
